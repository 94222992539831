import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { User } from './user.model';

import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AngularFireAuth } from 'angularfire2/auth'
import { AngularFirestore, AngularFirestoreDocument } from 'angularfire2/firestore';
import * as firebase from 'firebase/app';



@Injectable({
  providedIn: 'root'
})

export class AuthService {
  userLoggedIn: boolean;      // other components can check on this variable for the login status of the user

  user$: Observable<User>;

  constructor(
    private router: Router,
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore) {

    this.userLoggedIn = false;

    // Get the auth state, then fetch the Firestore user document or return null
    this.user$ = this.afAuth.authState.pipe(
      switchMap(user => {
        // Logged in
        if (user) {
          this.userLoggedIn = true;
          return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
        } else {
          this.userLoggedIn = false;
          // Logged out
          return of(null);
        }
      })
    )

  }


  loginUser(email: string, password: string): Promise<any> {
    return this.afAuth.auth.signInWithEmailAndPassword(email, password)
      .then(() => {
        console.log('Auth Service: loginUser: success');
        this.router.navigate(['/dashboard']);
      })
      .catch(error => {
        console.log('Auth Service: login error...');
        console.log('error code', error.code);
        console.log('error', error);
        if (error.code)
          return { isValid: false, message: error.message };
      });
  }

  async signupUser(user: any): Promise<any> {
    return this.afAuth.auth.createUserWithEmailAndPassword(user.email, user.password)
      .then((cred) => {

        console.log(cred.user)

        let result = cred.user

        const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${result.uid}`);

        const data = {
          uid: result.uid,
          email: result.email,
        }

        return userRef.set(data, { merge: true })

      })
      .catch(error => {
        console.log('Auth Service: signup error', error);
        if (error.code)
          return { isValid: false, message: error.message };
      });
  }


  logoutUser(): Promise<void> {
    return this.afAuth.auth.signOut()
      .then(() => {
        console.log('signed out')
        this.router.navigate([]);                    // when we log the user out, navigate them to home
      })
      .catch(error => {
        console.log('Auth Service: logout error...');
        console.log('error code', error.code);
        console.log('error', error);
        if (error.code)
          return error;
      });
  }


}

