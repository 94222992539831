<!--Navbar Start-->
<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark" (window:scroll)="windowScroll()"
  id="navbar">
  <div class="container">
    <a class="logo text-uppercase" href="/">
      <img src="assets/images/logo-dark.png" alt="" class="logo-dark" height="50" />
    </a>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
      aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleMenu()"
      style="box-shadow: none;">
      <i-feather name="menu" class="icon-dual-success mr-2"></i-feather>
    </button>

    <!-- <button (click)="toggleMenu()" style="background: transparent; fill: transparent; border: none;"
      data-target="#navbarCollapse">
      
    </button> -->


    <div class="collapse navbar-collapse" id="navbarCollapse">
      <ul class="navbar-nav ml-auto navbar-center" id="mySidenav">
        <li class="nav-item" [ngClass]="{'active':currentSection === 'home'}" (click)="collapseMenu()">
          <a [ngxScrollTo]="'#home'" href="javascript: void(0);" class="nav-link">Home</a>
        </li>
        <li class="nav-item" [ngClass]="{'active':currentSection === 'services'}" (click)="collapseMenu()">
          <a [ngxScrollTo]="'#services'" href="javascript: void(0);" class="nav-link">Services</a>
        </li>
        <li class="nav-item" [ngClass]="{'active':currentSection === 'features'}" (click)="collapseMenu()">
          <a [ngxScrollTo]="'#features'" href="javascript: void(0);" class="nav-link">Solutions</a>
        </li>

      </ul>
      <!-- <button class="btn btn-success btn-rounded navbar-btn" (click)="collapseMenu()" [routerLink]="['/consult']">Get
        Started</button> -->
    </div>
  </div>
</nav>
<!-- Navbar End -->


<div appScrollspy (sectionChange)="onSectionChange($event)" (click)="collapseMenu()">

  <!-- Hero section Start -->
  <section class="hero-section-2" id="home" style="background: url(assets/images/bg-2.png) center center;">
    <div class="container">
      <div class="row vertical-content justify-content-center">
        <div class="col-lg-6">
          <div class="hero-wrapper mb-4">
            <p class="font-16 text-uppercase">Discover Pacific Partners</p>
            <h1 class="hero-title mb-4">
              Bringing Your Ideas To Life
            </h1>

            <p>Marketing, Creative Media, and E-Commerce Consulting Agency </p>

            <div class="mt-4">
              <!-- <a href="javascript: void(0);" class="btn btn-primary mt-2 mr-2" [routerLink]="['/consult']">Let's
                Talk</a> -->
              <a href="javascript: void(0);" class="btn btn-success mt-2 mr-2" [routerLink]="['/services']">View
                Services</a>
            </div>
          </div>

        </div>

        <div class="col-lg-4 offset-lg-2">
          <div class="card mx-auto p-4 rounded mt-5 mt-lg-0" *ngIf="showConsultationForm == true">
            <div class="text-center">
              <p class="text-muted mb-2 font-13 text-uppercase">Schedule Consultation</p>
              <h5 class="form-title mb-4">Contact Form</h5>
            </div>
            <form class="registration-form" [formGroup]="consultationForm">
              <div class="form-group mb-4">
                <label class="font-15">Name*</label>
                <input type="text" class="form-control" id="name" placeholder="Pacific Partner" formControlName="name"
                  required>
              </div>
              <div class="form-group mb-4">
                <label class="font-15">Your Email*</label>
                <input type="email" class="form-control" id="email" placeholder="your@email.com" formControlName="email"
                  email required>
              </div>
              <div class="form-group mb-3">
                <label class="font-15">Phone*</label>
                <input type="phone" class="form-control" id="phone" placeholder="(123) 456-7890" formControlName="phone"
                  appPhoneMask required>
              </div>

              <div class="text-center">
                <a href="javascript: void(0);" class="btn btn-success mt-2 mr-2"
                  (click)="submitConsultationForm()">Let's
                  Talk</a>
              </div>


              <!-- <button type="button" class="btn btn-primary btn-block btn-sm" [routerLink]="['/consult']">Let's Talk<i
                  class="mdi mdi-telegram ml-2"></i></button> -->
            </form>
          </div>


          <!-- <div class="card mx-auto p-4 rounded mt-5 mt-lg-0" *ngIf="showConsultationForm == false">
            <div style="display: grid; justify-content: center;text-align: center;">
              Thank you for your interest, we'll be in touch soon!
            </div>

          </div> -->



        </div>
      </div>
      <!-- end row -->
    </div>
    <!-- end container -->
  </section>



  <!-- Services start -->
  <section class="section bg-light" id="services">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="text-center mb-5">
            <h5 class="text-primary text-uppercase small-title">Services</h5>
            <h4 class="mb-3">Services We Provide</h4>
            <p>We've been working together since 2020 helping entreprenuers and small business owners take control of
              their careers by helping them get off the ground and established.</p>
          </div>
        </div>
      </div>
      <div class="row" style="justify-content: center">
        <div class="col-xl-4 col-sm-6" *ngFor="let item of serviceData">
          <div class="text-center p-4 mt-3">
            <div class="avatar-md mx-auto mb-4">
              <span class="avatar-title rounded-circle bg-soft-primary" [routerLink]="['/services']">
                <i-feather name="{{item.icon}}" class="icon-dual-primary"></i-feather>
              </span>
            </div>
            <h5 class="font-18">{{item.title}}</h5>
            <p class="mb-0">{{item.text}}</p>
          </div>
        </div>

      </div>
      <!-- end row -->




      <div class="row mt-4">
        <div class="col-lg-12">
          <div class="text-center">
            <button class="btn btn-success btn-rounded navbar-btn" [routerLink]="['/services']">Services</button>
          </div>
        </div>
      </div>
    </div>
  </section>



  <!-- Features start -->
  <section class="section" id="features">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="text-center mb-5">
            <h5 class="text-primary text-uppercase small-title">Solutions</h5>
            <h4 class="mb-3">E-Commerce & Marketing</h4>
            <p>We offer a variety of services catered to your businesses needs.</p>
          </div>
        </div>
      </div>


      <div class="row">
        <div class="col-lg-5 ml-lg-auto">
          <div>
            <div class="avatar-md mb-4">
              <span class="avatar-title rounded-circle bg-soft-primary">
                <i-feather name="bar-chart-2" class="icon-dual-primary"></i-feather>
              </span>
            </div>
            <h5>E-Commerce</h5>
            <p class="mb-4">We build our websites from scratch, giving you absolute control over your customer's
              experience on your website. If you have an idea for a website other than an ecommerce business, let us
              know and we'll see what we can build together!</p>

            <div class="row">
              <div class="col-sm-6">
                <p>
                  <i-feather name="check" class="icon-dual-success mr-2"></i-feather> Payment Processing
                </p>
              </div>
              <div class="col-sm-6">
                <p>
                  <i-feather name="check" class="icon-dual-success mr-2"></i-feather> Scheduling
                </p>
              </div>
              <div class="col-sm-6">
                <p>
                  <i-feather name="check" class="icon-dual-success mr-2"></i-feather> Quickbooks Integration
                </p>
              </div>
              <div class="col-sm-6">
                <p>
                  <i-feather name="check" class="icon-dual-success mr-2"></i-feather> SMS Notifications
                </p>
              </div>
            </div>

            <!-- <div class="mt-4">
              <button class="btn btn-success btn-rounded navbar-btn" [routerLink]="['/ecommerce']">Ecommerce</button>
            </div> -->
          </div>
        </div>

        <div class="col-lg-5 ml-lg-auto col-sm-8">
          <div class="card-body">
            <img src="assets/images/features/img-1.png" alt="" class="img-fluid mx-auto d-block">
          </div>
        </div>
      </div>
      <!-- end row -->




      <div class="row mt-5 pt-5">
        <div class="col-lg-5 ml-lg-auto col-sm-8">
          <div class="card-body">
            <img src="assets/images/features/img-2.png" alt="" class="img-fluid mx-auto d-block">
          </div>
        </div>

        <div class="col-lg-5 ml-lg-auto">
          <div class="mt-4 mt-lg-0">
            <div class="avatar-md mb-4">
              <span class="avatar-title rounded-circle bg-soft-primary">
                <i-feather name="pie-chart" class="icon-dual-primary"></i-feather>
              </span>
            </div>
            <h5>Marketing & Media</h5>
            <p class="mb-4">Let us help you strategize your brand and digital strategy.</p>

            <div class="row">
              <div class="col-sm-6">
                <p>
                  <i-feather name="check" class="icon-dual-success mr-2"></i-feather> Logo & Branding
                </p>
              </div>
              <div class="col-sm-6">
                <p>
                  <i-feather name="check" class="icon-dual-success mr-2"></i-feather> Copywriting
                </p>
              </div>
              <div class="col-sm-6">
                <p>
                  <i-feather name="check" class="icon-dual-success mr-2"></i-feather> SEO
                </p>
              </div>
              <div class="col-sm-6">
                <p>
                  <i-feather name="check" class="icon-dual-success mr-2"></i-feather> Google Analytics
                </p>
              </div>
              <div class="col-sm-6">
                <p>
                  <i-feather name="check" class="icon-dual-success mr-2"></i-feather> Keyword Research
                </p>
              </div>
              <div class="col-sm-6">
                <p>
                  <i-feather name="check" class="icon-dual-success mr-2"></i-feather> Digital Assets
                </p>
              </div>
            </div>

            <!-- <div class="mt-4">
              <button class="btn btn-success btn-rounded navbar-btn" [routerLink]="['/marketing']">Marketing</button>
            </div> -->
          </div>
        </div>

      </div>

      <div class="row mt-5 pt-5">
        <div class="col-lg-5 ml-lg-auto">
          <div class="mt-4 mt-lg-0">
            <div class="avatar-md mb-4">
              <span class="avatar-title rounded-circle bg-soft-primary">
                <i-feather name="Compass" class="icon-dual-primary"></i-feather>
              </span>
            </div>
            <h5>Business Consulting Services</h5>
            <p class="mb-4">Whether you're just starting out or you're a year down the road, the team at Pacific
              Partners has the skills to help
              take your business to the next level. We're not your typical agency, we all take on projects outside of
              day jobs and work with visionaries to help create the brand and business of their dreams. No matter where
              you are in the process, we can help push your brand forward. </p>

            <div class="row">
              <div class="col-sm-12">
                <p>
                  <i-feather name="check" class="icon-dual-success mr-2"></i-feather> Business Consulting
                </p>
              </div>
              <div class="col-sm-12">
                <p>
                  <i-feather name="check" class="icon-dual-success mr-2"></i-feather> People Management Counseling
                </p>
              </div>
              <div class="col-sm-12">
                <p>
                  <i-feather name="check" class="icon-dual-success mr-2"></i-feather> Business Stress & Venting Sessions
                </p>
              </div>
            </div>

            <!-- <div class="mt-4">
              <button class="btn btn-success btn-rounded navbar-btn" [routerLink]="['/marketing']">Marketing</button>
            </div> -->
          </div>
        </div>

        <div class="col-lg-5 ml-lg-auto col-sm-8">
          <div class="card-body">
            <img src="assets/images/features/img-3.png" alt="" class="img-fluid mx-auto d-block">
          </div>
        </div>

      </div>
      <!-- end row -->
    </div>
    <!-- end container -->
  </section>
  <!-- Features end -->


  <!-- Pricing start -->
  <section class="section bg-light" id="pricing">
    <div class="container">
      <!-- <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="text-center mb-5">
            <h5 class="text-primary text-uppercase small-title">Pricing</h5>
            <h4 class="mb-3">Build Your Bundle</h4>
            <p>Let us help you build the right bundle for your business.</p>
          </div>
        </div>
      </div> -->
      <!-- end row -->

      <!-- <div class="row">
          <div class="col-lg-12">
            <div class="text-center mb-4">
              <ul class="nav nav-pills pricing-nav-tabs mb-4">
                <li class="nav-item">
                  <a class="nav-link active" href="javascript: void(0);">Monthly</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="javascript: void(0);">Yearly</a>
                </li>
              </ul>
            </div>
          </div>
        </div> -->

      <div class="row justify-content-center">
        <div class="row justify-content-center">
          <div class="col-lg-8 ">
            <div class="text-center mb-5">
              <h5 class="text-primary text-uppercase small-title">Subscribe</h5>
              <h4 class="mb-3">Stay </h4>
              <p>We'll be in touch!</p>
            </div>
          </div>

          <!-- <div class="col-xl-8 col-lg-10">
            <div class="text-center">
              <div class="subscribe">
                <div class="mt-4">
                  <button class="btn btn-success btn-rounded navbar-btn" [routerLink]="['/contact']">Contact Us</button>
                </div>
              </div>
            </div>
          </div> -->
        </div>

        <div class="row justify-content-center">

          <div class="col-md-9 text-center mt-4">
            <div class="subscribe" *ngIf="showSubscribeForm == true">
              <form [formGroup]="subscribeForm">
                <div class="row">
                  <div class="col-md-9">
                    <div>
                      <input type="text" formControlName="email" class="form-control"
                        placeholder="Enter your E-mail address">
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="mt-3 mt-md-0">
                      <button type="button" class="btn btn-success btn-block"
                        (click)="submitSubscribeForm()">Subscribe</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- end row -->
    </div>
    <!-- end container -->
  </section>
  <!-- Pricing end -->


  <!-- Cta end -->


  <!-- Footer start -->
  <footer class="footer">
    <div class="container">
      <div class="row justify-content-center" style="text-align: center;">
        <!-- <div class="col-lg-4 col-sm-6">
          <div>
            <h5 class="mb-4 footer-list-title">Pacific Partners</h5>
            <p>Digital Media & E-Commerce Consulting Agency</p>
          </div>
        </div>
        <div class="col-lg-2 offset-lg-1 col-sm-6">
          <div>
            <h5 class="mb-4 footer-list-title">Company</h5>
            <ul class="list-unstyled footer-list-menu">
              <li><a href="javascript: void(0);">About Us</a></li>
              <li><a href="javascript: void(0);">Media & Press</a></li>
              <li><a href="javascript: void(0);">Career</a></li>
              <li><a href="javascript: void(0);">Blog</a></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-2 col-sm-6">
          <div>
            <h5 class="mb-4 footer-list-title">More Info</h5>
            <ul class="list-unstyled footer-list-menu">
              <li><a href="javascript: void(0);">Pricing</a></li>
              <li><a href="javascript: void(0);">For Marketing</a></li>
              <li><a href="javascript: void(0);">For CEOs </a></li>
              <li><a href="javascript: void(0);">For Agencies</a></li>
              <li><a href="javascript: void(0);">Our Apps</a></li>
            </ul>
          </div>
        </div> -->
        <div class="row">


          <div class="col-lg-12 col-sm-6 text-center">
            <div>
              <!-- <h5 class="mb-4 footer-list-title">Contact</h5> -->

              <div class="col-lg-12 col-sm-12" style="text-align: left">


                <h5 style="color: white;">What's the Pacific Partner difference?</h5>

                <hr>

                <p style="color: white;">From the beginning, our goal has been to offer top-shelf service and experience
                  to our clients. When you partner with pacific partners, you're partnering with people who have worked
                  at Microsoft, start-ups, and financial firms. People who have taken an idea, developed it, finalized
                  their proof of concept, and earned their first dollar. Trust us when we say that we know how to get an
                  idea off the ground. We look forward to supporting you as we grow your business together.</p>

                <!-- <p>admin@pacificpartners.io</p> -->
                <!-- <p>(206)-123-4444</p> -->
                <!-- <div class="media">
                <i-feather name="map-pin" class="icon-dual-light icons-sm mt-1 mr-2"></i-feather>
                <div class="media-body">
                  <p>476 University Drive Ridge, IL 61257</p>
                </div>
              </div> -->
                <!-- <div class="media" style="text-align: center;">
                <i-feather name="mail" class="icon-dual-light icons-sm mt-1 mr-2"></i-feather>
                <div class="media-body">
                  <p>support@pacificpartners.io</p>
                </div>
              </div>
              <div class="media" style="text-align: center;">
                <i-feather name="phone" class="icon-dual-light icons-sm mt-1 mr-2"></i-feather>
                <div class="media-body">
                  <p>(206)-123-4444</p>
                </div>
              </div> -->
              </div>
            </div>
          </div>
        </div>
        <!-- end row -->
      </div>
    </div>
    <!-- end container -->

  </footer>
  <!-- Footer end -->