import { SideNavInterface } from '../../interfaces/side-nav.type';
export const ROUTES: SideNavInterface[] = [
    {
        path: '/dashboard/admin',
        title: 'Dashboard',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'home',
        submenu: []
    },
    {
        path: '/dashboard/customers',
        title: 'Customers',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'contacts',
        submenu: []
    },
    {
        path: '/dashboard/orders',
        title: 'Orders',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'reconciliation',
        submenu: []
    },
    {
        path: '/dashboard/services',
        title: 'Services',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'shopping-cart',
        submenu: []
    },
    // {
    //     path: '/dashboard/banking',
    //     title: 'Banking',
    //     iconType: 'nzIcon',
    //     iconTheme: 'outline',
    //     icon: 'bank',
    //     submenu: []
    // },
    // {
    //     path: '',
    //     title: 'Multi Level Menu',
    //     iconType: 'nzIcon',
    //     iconTheme: 'outline',
    //     icon: 'appstore',
    //     submenu: [
    //         { 
    //             path: '',
    //             title: 'Level 1', 
    //             iconType: '', 
    //             icon: '',
    //             iconTheme: '',
    //             submenu: [
    //                 {
    //                     path: '',
    //                     title: 'Level 2',
    //                     iconType: 'nzIcon',
    //                     iconTheme: 'outline',
    //                     icon: '',
    //                     submenu: []
    //                 }    
    //             ] 
    //         }
    //     ]
    // }
]