<!--Navbar Start-->
<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark" id="navbar"
  (window:scroll)="windowScroll()">
  <div class="container">
    <!-- LOGO -->
    <a class="logo text-uppercase" href="/">
      <img src="assets/images/logo-dark.png" alt="" class="logo-dark" height="50" />
    </a>

    <!-- <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
      aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleMenu()"
      style="box-shadow: none;">
      <i-feather name="menu" class="icon-dual-success mr-2"></i-feather>
    </button> -->
    <button class="btn btn-success btn-rounded navbar-btn" [routerLink]="['']">Home</button>
    <!-- <nz-badge [nzCount]="checkoutItems.length" style="margin-right: 24px;">
      <button nz-button nz-tooltip [nz-tooltip]=" checkoutItems.length ? 'Checkout' : ''" nzType="primary"
        (click)="open()" class="btn-success navbar-toggler" [nzSize]="'large'" nzShape="circle"><i nz-icon
          nzType="shopping-cart"></i>
      </button>
    </nz-badge>-->

    <!-- <div class="collapse navbar-collapse" id="navbarCollapse" style="justify-content: end">

      <nz-badge [nzCount]="checkoutItems.length" style="margin-right: 24px;">
        <button nz-button nz-tooltip [nz-tooltip]=" checkoutItems.length ? 'Checkout' : ''" nzType="primary"
          (click)="open()" class="btn-success navbar-toggler" nzShape="circle"><i nz-icon nzType="shopping-cart"></i>
        </button>
      </nz-badge>

    </div> -->
  </div>
</nav>



<section id="home"
  style="background: url(assets/images/bg-2.png) center center; height: 25vh; justify-content: center; align-items: center; text-align: center; padding: 100px 0 100px 0">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <div class="hero-wrapper text-center mb-4">
          <p class="font-16 text-uppercase">Pacific Partners</p>
          <h1 class="hero-title mb-4">
            <span class="text-success">Services</span>
          </h1>

          <div class="desk-top-special" *ngIf="checkoutItems.length">
            <nz-badge class="desk-top-special" [nzCount]="checkoutItems.length" style="margin-right: 24px;">
              <button nz-button nzType="primary" (click)="open()" class="btn-success desk-top-special"
                [nzSize]="'large'" nzShape="round">Checkout<i nz-icon nzType="shopping-cart"
                  style="font-size: 20px; margin-left: 15px;"></i>
              </button>
            </nz-badge>
          </div>

        </div>
      </div>
    </div>
  </div>
</section>


<div class="container">
  <div class="row justify-content-center">
    <div class="col-lg-12">
      <div class="text-left mt-8 mb-8">
        <div class="subscribe">
          <div class="row">
            <div class="col-lg-12">
              <nz-card *ngFor="let item of products;  let i=index">
                <div class="d-flex justify-content-between">
                  <div class="media align-items-center">
                    <!-- <nz-avatar nzShape="square" nzSrc="assets/images/others/thumb-3.jpg">
                    </nz-avatar> -->
                    <div class="m-l-10">
                      <h4 class="m-b-0">{{item.name}}</h4>
                      <p>{{item.price | currency}}</p>
                    </div>
                  </div>
                  <div>
                    <button nz-button nzType="default" nzShape="circle" id="checkout-button"
                      (click)="addItemToCart(item)" nz-tooltip nzTooltipTitle="Add {{item.name}} to cart.">
                      <i nz-icon nzType="plus" theme="outline"></i>
                    </button>
                  </div>
                </div>
                <div class="m-t-40">
                  <h6>Description:</h6>

                  <p>{{item.description}}</p>
                </div>
              </nz-card>
            </div>
          </div>
        </div>
      </div>
    </div>





    <nz-drawer [nzClosable]="true" [nzVisible]="visible" nzPlacement="bottom" nzHeight="100vh" (nzOnClose)="close()">

      <ng-container *nzDrawerContent>
        <div class="container">
          <div class="row justify-content-center">

            <div class="col-lg-12" style="justify-content:center;">
              <div class="row col-lg-12 text-center mb-4">

                <h2>Your Cart</h2>

              </div>

              <div *ngIf="checkoutItems.length < 1" style="display: grid; justify-content: center;">

                <p>Your cart is empty. Please add a service to checkout.</p>

              </div>


              <nz-list *ngIf="checkoutItems.length > 0">

                <nz-list-item *ngFor="let item of checkoutItems">

                  <div class="row col-lg-12">





                    <div style="display: flex; justify-content: space-between; align-items: center;">
                      {{item.name}}

                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <p style="margin: 0px 15px 0px 0px;">{{item.quantity * item.price | currency}}</p>

                        <i nz-icon nzType="delete" theme="outline" (click)="removeItemFromCart(item)" nz-tooltip
                          [nz-tooltip]="'Remove from cart.'"></i>

                      </div>



                    </div>







                    <div *ngIf="item.tag == 'M' || item.tag == 'H'"
                      style="display: flex; justify-content: space-evenly; align-items: center;">

                      <button nz-button nzType="default" nzShape="circle" id="checkout-button"
                        (click)="increaseQuantity(item)" nz-tooltip nzTooltipTitle="Add Month">
                        <i nz-icon nzType="plus" theme="outline"></i>
                      </button>


                      <label *ngIf="item.tag == 'M'">Months</label>
                      <label *ngIf="item.tag == 'H'">Hours</label>
                      <p style="margin: 0px">{{item.quantity}}</p>


                      <button nz-button nzType="default" nzShape="circle" id="checkout-button"
                        (click)="decreaseQuantity(item)" nz-tooltip nzTooltipTitle="Remove Month">
                        <i nz-icon nzType="minus" theme="outline"></i>
                      </button>

                    </div>
                  </div>
                </nz-list-item>
              </nz-list>


              <div class="text-center" *ngIf="checkoutItems.length"
                style="border-top: 1px solid #34D1BF;padding-top: 30px;">
                <div class="row col-lg-12">
                  <nz-list>
                    <nz-list-tem>
                      <div style="display: flex; justify-content: right; align-items: center;">
                        <p style="margin-right: 10px;">Total: </p>
                        <p>{{this.total | currency}}</p>
                      </div>
                    </nz-list-tem>
                  </nz-list>
                </div>

                <!-- <button nz-button nz-tooltip [nz-tooltip]="checkoutItems.length ? 'Checkout' : ''" nzType="primary"
                  class="btn-success" [nzSize]="'large'" nzShape="round" (click)="stripeCheckout()">
                  Checkout
                </button> -->
                <button nz-button nz-tooltip [nz-tooltip]="checkoutItems.length ? 'Checkout' : ''" nzType="primary"
                  class="btn-success" [nzSize]="'large'" nzShape="round" (click)="stripeCheckoutMulti()">
                  Checkout
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </nz-drawer>



  </div>
</div>


<div class="test-class" style="width: 100vw; height: 30px; position: fixed; bottom: 30px; left: 80%;">
  <nz-badge [nzCount]="checkoutItems.length" style="margin-right: 24px;">
    <button nz-button nz-tooltip [nz-tooltip]=" checkoutItems.length ? 'Checkout' : ''" nzType="primary"
      (click)="stripeCheckout()" class="btn-success" [nzSize]="'large'" nzShape="circle"><i nz-icon
        nzType="shopping-cart"></i>
    </button>
  </nz-badge>
</div>