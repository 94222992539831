import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { FullLayoutComponent } from "./layouts/full-layout/full-layout.component";
import { CommonLayoutComponent } from "./layouts/common-layout/common-layout.component";

import { FullLayout_ROUTES } from "./shared/routes/full-layout.routes";
import { CommonLayout_ROUTES } from "./shared/routes/common-layout.routes";
import { HomeComponent } from './home/home.component';
import { ServicesComponent } from './home/services/services.component';
import { CheckoutComponent } from './home/checkout/checkout.component';
import { CartComponent } from './home/cart/cart.component';
import { ContactFormComponent } from './home/contact-form/contact-form.component';
import { EcommerceComponent } from './home/ecommerce/ecommerce.component';
import { MarketingComponent } from './home/marketing/marketing.component';
import { BrandingFormComponent } from './home/branding-form/branding-form.component';
import { ConsultationFormComponent } from './home/consultation-form/consultation-form.component';

const appRoutes: Routes = [
    {
        path: '',
        component: HomeComponent
    },
    {
        path: 'services',
        component: ServicesComponent
    },
    {
        path: 'checkout',
        component: CheckoutComponent
    },
    {
        path: 'cart',
        component: CartComponent
    },
    {
        path: 'services',
        component: CartComponent
    },
    {
        path: 'contact',
        component: ContactFormComponent
    },
    {
        path: 'ecommerce',
        component: EcommerceComponent
    },
    {
        path: 'marketing',
        component: MarketingComponent
    },
    {
        path: 'client-branding-form',
        component: BrandingFormComponent
    },
    {
        path: 'consult',
        component: ConsultationFormComponent
    },
    {
        path: 'admin',
        redirectTo: '/dashboard/',
        pathMatch: 'full'
    },
    {
        path: '',
        component: CommonLayoutComponent,
        children: CommonLayout_ROUTES
    },
    {
        path: '',
        component: FullLayoutComponent,
        children: FullLayout_ROUTES
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, {
            preloadingStrategy: PreloadAllModules,
            anchorScrolling: 'enabled',
            scrollPositionRestoration: 'enabled'
        })
    ],
    exports: [
        RouterModule
    ]
})

export class AppRoutingModule {
}