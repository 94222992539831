import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-branding-form',
  templateUrl: './branding-form.component.html',
  styleUrls: ['./branding-form.component.css', '../home.component.scss'],
})
export class BrandingFormComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
