
<!--Navbar Start-->
<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark"
  id="navbar">
  <div class="container">
    <!-- LOGO -->
    <a class="logo text-uppercase" href="/">
      <img src="assets/images/logo-dark.png" alt="" class="logo-dark" height="20" />
    </a>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
      aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
      <i class="mdi mdi-menu"></i>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse" style="justify-content: end">
      <button class="btn btn-success btn-rounded navbar-btn" [routerLink]="['']">Home</button>
    </div>
  </div>
</nav>



<section id="home" style="background: url(assets/images/bg-2.png) center center; height: 25vh; justify-content: center; align-items: center; text-align: center; padding: 100px 0 100px 0">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="hero-wrapper text-center mb-4">
            <p class="font-16 text-uppercase">Pacific Partners</p>
            <h1 class="hero-title mb-4">
              <span class="text-success">Cart</span>
            </h1>
            <p>Here's what's in your cart!</p>
          </div>
        </div>
      </div>
      <!-- end row -->
    </div>
    <!-- end container -->
  </section>
  <!-- Hero section End -->

<div style="display: flex; justify-content: center; align-items: center">
  <nz-card>
    <nz-table #productsListTable [nzData]="displayData" [nzShowPagination]="false">
        <thead>
            <tr>
                <th *ngFor="let column of orderColumn" [nzSortFn]="column.compare">{{ column.title }}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of productsListTable.data;  let i=index">
                <td>#{{item.id}}</td>
                <td>
                    <div class="d-flex align-items-center">
                        <!-- <nz-avatar nzShape="square" [nzSize]="60" nzIcon="picture" [nzSrc]="item.avatar"></nz-avatar> -->
                        <span class="m-l-10">{{item.name}}</span>
                    </div>
                </td>
                <td>{{item.category}}</td>
                <td>${{item.price | number:'3.2-5'}}</td>

                <td class="text-md-right">
                    <button nz-button nzType="default" nzShape="circle" nz-tooltip nzTooltipTitle="Delete">
                        <i nz-icon nzType="delete" theme="outline"></i>
                    </button>
                </td>
            </tr>
        </tbody>
    </nz-table>
</nz-card>
</div>

<div style="display: grid; justify-content: center;">
  <button class="btn btn-success btn-rounded navbar-btn" [routerLink]="['/checkout']">Checkout</button>
</div>