<!--Navbar Start-->
<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark" id="navbar"
  (window:scroll)="windowScroll()">
  <div class="container">
    <!-- LOGO -->
    <a class="logo text-uppercase" href="/">
      <img src="assets/images/logo-dark.png" alt="" class="logo-dark" height="20" />
    </a>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
      aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
      <i class="mdi mdi-menu"></i>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse" style="justify-content: end">
      <button class="btn btn-success btn-rounded navbar-btn" [routerLink]="['']">Home</button>
    </div>
  </div>
</nav>

<!-- Navbar End -->
<div>
  <!-- Hero section Start -->
  <section id="home"
    style="background: url(assets/images/bg-2.png) center center; height: 25vh; justify-content: center; align-items: center; text-align: center; padding: 100px 0 100px 0">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="hero-wrapper text-center mb-4">
            <p class="font-16 text-uppercase">Pacific Partners</p>
            <h1 class="hero-title mb-4">

              <span class="text-success">Ecommerce</span>
            </h1>

            <p>Let's bring your idea to life!</p>

            <!-- <div class="text-center mt-4">
              <div class="subscribe">
                <form>
                  <div class="row">
                    <div class="col-md-9">
                      <div>
                        <input type="text" class="form-control" placeholder="Enter your E-mail address">
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="mt-3 mt-md-0">
                        <button type="button" class="btn btn-success btn-block">Subscribe</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <!-- end row -->
    </div>
    <!-- end container -->
  </section>
  <!-- Hero section End -->

  <div class="container">
    <div class="row">
      <div class="col-lg-4" *ngFor="let item of pricingData">
        <div class="pricing-plan card text-center">
          <div class="card-body p-4">
            <h5 class="mt-2 mb-5">{{item.title}}</h5>

            <!-- <h1 class="mb-5"><sup><small>$</small></sup>{{item.price/100}}/ <span class="font-16">Yr</span></h1> -->
            <h1 class="mb-5"><sup><small>$</small></sup>{{item.price/100}}</h1>

            <div>
              <a href="javascript: void(0);" class="btn btn-primary">Join now</a>
            </div>
            <div class="plan-features mt-5">
              <p>Support Hours : <span class="text-success">{{item.bandwidth}}</span></p>
              <p>Pages : <span class="text-success">{{item.onlinespace}}</span></p>
              <p>Support Hours : <span class="text-success">{{item.support}}</span></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>