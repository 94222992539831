import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { AngularFirestore } from 'angularfire2/firestore';
import { NzMessageService } from 'ng-zorro-antd/message';



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss', './home.component.css'],
  encapsulation: ViewEncapsulation.None,
})




export class HomeComponent implements OnInit {
  currentSection = 'home';

  serviceData = [
    { icon: 'Monitor', title: 'Websites', text: 'Fully managed websites, with the ultimate customization.' },
    { icon: 'credit-card', title: 'Payment Processing', text: 'Payment processing options include: flat-rate, subscription, and invoices.' },
    { icon: 'Calendar', title: 'Online Scheduling', text: 'Enable your customers to book your services, so you know where to be and when to be there.' },
    { icon: 'Clipboard', title: 'Branding', text: 'Build a brand around your vision that allows for customers to identify your business.' },
    { icon: 'Compass', title: 'Strategy', text: 'To help you grow your business and offer advice on how to overcome difficult business situations.' },
    { icon: 'tablet', title: 'Social Media Management', text: 'We offer a fully managed Social Media service. We create your content and manage your posts for you.' },
    { icon: 'Search', title: 'SEO', text: 'We make sure the customers you want to reach are able to reach you as well.' },
    { icon: 'bar-chart-2', title: 'Google Analytics', text: 'Get insights into your website\'s visitors and their engagement with your site.' },
  ];



  subscribeForm: FormGroup;
  consultationForm: FormGroup;
  showConsultationForm: boolean = true;
  showSubscribeForm: boolean = true;


  constructor(
    private formBuilder: FormBuilder,
    private afs: AngularFirestore,
    private message: NzMessageService,
  ) { }

  ngOnInit(): void {

    this.consultationForm = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
      phone: [null, [Validators.required]],
      name: [null, [Validators.required]],
    })

    this.subscribeForm = this.formBuilder.group({
      email: [null, [Validators.required]],
    })
  }

  createInfoMessage(message): void {
    this.message.info(message);
  }


  async submitConsultationForm() {
    const formValue = this.consultationForm.value;

    try {
      await this.afs.collection('consultation').add(formValue);

      this.createInfoMessage(`Thank you! We'll be in touch!`)

      this.showConsultationForm = false;

    } catch (err) {
      console.error(err)
    }
  }

  async submitSubscribeForm() {
    const formValue = this.subscribeForm.value;

    try {
      await this.afs.collection('subscribers').add(formValue);

      this.createInfoMessage(`Your subscribed!`)

      this.showSubscribeForm = false;

    } catch (err) {
      console.error(err)
    }
  }


  /**
   * Window scroll method
   */
  windowScroll() {
    const navbar = document.getElementById('navbar');
    if (document.body.scrollTop >= 50 || document.documentElement.scrollTop > 50) {
      navbar.classList.add('nav-sticky');
    } else {
      navbar.classList.remove('nav-sticky');
    }
  }

  /**
   * Section changed method
   * @param sectionId specify the current sectionID
   */
  onSectionChange(sectionId: string) {
    this.currentSection = sectionId;
  }

  /**
   * Toggle navbar
   */
  toggleMenu() {
    document.getElementById('navbarCollapse').classList.toggle('show');
  }

  collapseMenu() {
    document.getElementById('navbarCollapse').classList.contains('show') ? document.getElementById('navbarCollapse').classList.toggle('show') : console.log('thank you have a great day!')
  }

}
