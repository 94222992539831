import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ProductService } from 'src/app/services/product.service';

import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AngularFirestore } from 'angularfire2/firestore';
import { AngularFireFunctions } from 'angularfire2/functions';


declare var Stripe;

interface Product {
    id?: number;
    name: string;
    price: number;
    description?: string;
    quantity: number;
}

@Component({
    selector: 'app-services',
    templateUrl: './services.component.html',
    styleUrls: ['./services.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class ServicesComponent implements OnInit {

    products: any[];
    stripeStatus: string;                           // {'', 'cancel', 'success'}
    checkoutItems: any[];
    total: any[];


    constructor(
        private productService: ProductService,
        private firestore: AngularFirestore,
        private afFun: AngularFireFunctions,
        private activatedRoute: ActivatedRoute,
        private router: Router,
    ) {
        this.productService.getProducts().subscribe(products => {
            //console.log(items);
            this.products = products;
            console.log(this.products)
        });
    }

    getStripeStatus(): string {
        let action = this.activatedRoute.snapshot.queryParamMap.get('action');              // ex: '/home?action=success'
        console.log('action = ', action);
        if (action && action == 'cancel' || action == 'success')
            return action;
        return '';
    }

    // stripeCheckout(productId: any): void {
    //     console.log('checking out with item id: ' + productId);

    //     var stripe = Stripe(environment.stripe.key);

    //     this.afFun.httpsCallable("stripeCheckout")({ id: productId })
    //         .subscribe(result => {
    //             console.log({ result });

    //             stripe.redirectToCheckout({
    //                 sessionId: result,
    //             }).then(function (result) {
    //                 console.log(result.error.message);
    //             }).catch((error) => {
    //                 console.log(error);
    //                 console.log(JSON.stringify(error));
    //             });
    //         })
    // }


    // line_items: [{
    //     name: name,
    //     description: description,
    //     amount: Math.round(price * 100),                // round to the nearest whole number so we don't have float errors
    //     currency: 'usd',
    //     quantity: 1
    // }],


    formatLineItems(data) {
        // const lineItemNames = ['services: '];

        const lineItemTotal = data.map((e) => e.price).reduce((acc, item) => {
            acc = acc + item;
            return acc;
        })

        const lineItemNames = data.map((e) => e.name).join(', ')

        const lineItemDescriptions = data.map((e) => e.name + ": " + e.description).join(', ')


        const stripeData = {
            name: lineItemNames,
            description: lineItemDescriptions,
            amount: (lineItemTotal * 100),
            currency: 'usd',
            quantity: 1
        }

        console.log(stripeData)
        return stripeData;
    }




    stripeCheckoutMulti() {
        this.formatLineItems(this.checkoutItems)

        var stripe = Stripe(environment.stripe.key);

        this.afFun.httpsCallable("stripeCheckoutMulti")(this.formatLineItems(this.checkoutItems))
            .subscribe(result => {
                console.log({ result });

                stripe.redirectToCheckout({
                    sessionId: result,
                }).then(function (result) {
                    console.log(result.error.message);
                }).catch((error) => {
                    console.log(error);
                    console.log(JSON.stringify(error));
                });
            })
    }

    stripeCheckout() {
        var stripe = Stripe(environment.stripe.key);

        this.afFun.httpsCallable("stripeCheckout")(this.checkoutItems)
            .subscribe(result => {
                console.log({ result });

                stripe.redirectToCheckout({
                    sessionId: result,
                }).then(function (result) {
                    console.log(result.error.message);
                }).catch((error) => {
                    console.log(error);
                    console.log(JSON.stringify(error));
                });
            })
    }

    ngOnInit(): void {
        this.stripeStatus = '';
        this.checkoutItems = [];
    }

    addItemToCart(item) {
        this.checkoutItems.push(item);
        console.log(this.checkoutItems);
        this.cartTotal(this.checkoutItems);
    }


    removeItemFromCart(item) {
        let id = item.id
        // get index of object with id of 37
        const removeIndex = this.checkoutItems.findIndex(item => item.id === id);
        // remove object
        this.checkoutItems.splice(removeIndex, 1);
        this.cartTotal(this.checkoutItems);
    }


    increaseQuantity(item) {
        item.quantity++;
        this.cartTotal(this.checkoutItems)
    }

    decreaseQuantity(item) {
        item.quantity = item.quantity - 1;

        if (item.quantity == 0) {
            return this.removeItemFromCart(item)
        }
    }


    cartTotal(checkoutItems) {
        this.total = checkoutItems.reduce((accum, item) => accum + item.price * item.quantity, 0)

    }


    visible = false;


    open(): void {
        this.visible = true;
    }

    close(): void {
        this.visible = false;
    }





    windowScroll() {
        const navbar = document.getElementById('navbar');
        if (document.body.scrollTop >= 50 || document.documentElement.scrollTop > 50) {
            navbar.classList.add('nav-sticky');
        } else {
            navbar.classList.remove('nav-sticky');
        }
    }

    toggleMenu() {
        document.getElementById('navbarCollapse').classList.toggle('show');
    }



}
