
<!--Navbar Start-->
<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark"
  id="navbar">
  <div class="container">
    <!-- LOGO -->
    <a class="logo text-uppercase" href="/">
      <img src="assets/images/logo-dark.png" alt="" class="logo-dark" height="20" />
    </a>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
      aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
      <i class="mdi mdi-menu"></i>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse" style="justify-content: end">
      <button class="btn btn-success btn-rounded navbar-btn" [routerLink]="['']">Home</button>
    </div>
  </div>
</nav>



<section class="hero-section-5" id="home" style="background: url(assets/images/bg-2.png) center center;">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">

          <div class="hero-wrapper text-center mb-4">
            <p class="font-16 text-uppercase">Pacific Partners</p>
            <h1 class="hero-title mb-4">
              
              <span class="text-success">Branding Form</span>
            </h1>

            <p>Yay! New Friends!</p>

            <div class="text-center mt-4">
              <div class="subscribe">
                <form>
                  <div class="row">
                    <form class="registration-form">
                        <div class="form-group mb-4">
                          <label class="font-15">Brand Name</label>
                          <input type="text" class="form-control" id="name" placeholder="">
                        </div>
                        <div class="form-group mb-4">
                          <label class="font-15">What sector/industry are you in?</label>
                          <p>Describe your product/service in clear, concise words.</p>
                          <input type="email" class="form-control" id="email" placeholder="">
                        </div>
                        <div class="form-group mb-3">
                          <label class="font-15">What are the core values or ideals that are part of the essence of your product and brand?</label>
                          <input type="password" class="form-control" id="password" placeholder="">
                        </div>
                        <div class="form-group mb-3">
                          <label class="font-15"> What does your product offer that is unique and different? </label>
                          <input type="password" class="form-control" id="password" placeholder="">
                        </div>
                        <div class="form-group mb-3">
                          <label class="font-15">How does your product differ from your competitors?</label>
                          <input type="password" class="form-control" id="password" placeholder="">
                        </div>



                        <div class="form-group mb-3">
                          <label class="font-15">Who are your customers? </label>
                          <input type="password" class="form-control" id="password" placeholder="">
                        </div>
                        <div class="form-group mb-3">
                          <label class="font-15">What are the characteristics of your target audience and possible consumers?</label>
                          <input type="password" class="form-control" id="password" placeholder="">
                        </div>
                        <div class="form-group mb-3">
                          <label class="font-15">What is the slogan, phrase, or power mantra that you chose for your business?</label>
                          <p>If you don't have it yet, you can start by writing phrases that are important to you or that inspire you. </p>
                          <input type="password" class="form-control" id="password" placeholder="">
                        </div>
                        <div class="form-group mb-3">
                          <label class="font-15">List words that resonate with the value and emotion of your product/brand.</label>
                          <input type="password" class="form-control" id="password" placeholder="">
                        </div>
                        <div class="form-group mb-3">
                          <label class="font-15">Who are your competitors?</label>
                          <p>Name at least three - Please put their social network handlers or website URL to check its contents.</p>
                        </div>

                        <div style="display: flex; flex-direction: column">
                            
                        <div style="display: flex; flex-direction: row; justify-content: space-between;">
                            <div class="form-group mb-3" style="width: 45%">
                                <label class="font-15">Competitor 1</label>
                                <input type="password" class="form-control" id="password" placeholder="">
                            </div>
                            <div class="form-group mb-3" style="width: 45%">
                                  <label class="font-15">Website</label>
                                  <input type="password" class="form-control" id="password" placeholder="">
                            </div>
                        </div>

                        <div style="display: flex; flex-direction: row; justify-content: space-between;">
                            <div class="form-group mb-3" style="width: 45%">
                                <label class="font-15">Competitor 2</label>
                                <input type="password" class="form-control" id="password" placeholder="">
                            </div>
                            <div class="form-group mb-3" style="width: 45%">
                                  <label class="font-15">Website</label>
                                  <input type="password" class="form-control" id="password" placeholder="">
                            </div>
                        </div>
                        <div style="display: flex; flex-direction: row; justify-content: space-between;">
                            <div class="form-group mb-3" style="width: 45%">
                                <label class="font-15">Competitor 3</label>
                                <input type="password" class="form-control" id="password" placeholder="">
                            </div>
                            <div class="form-group mb-3" style="width: 45%">
                                  <label class="font-15">Website</label>
                                  <input type="password" class="form-control" id="password" placeholder="">
                            </div>
                        </div>
                    </div>

                    <div class="form-group mb-3">
                        <label class="font-15">Add URLs or social media of companies you feel resonate similarly to your brand. It does not have to be in the same industry (B2C or even at home appliances are fair game)</label>
                        <input type="password" class="form-control" id="password" placeholder="">
                      </div>
                    <div class="form-group mb-3">
                        <label class="font-15">Please list what you like about each company and how it relates to your own vision of our brand.</label>
                        <input type="password" class="form-control" id="password" placeholder="">
                      </div>
                    <div class="form-group mb-3">
                        <label class="font-15">What would you like your audience to take away from and or feel after interacting with your brand?</label>
                        <input type="password" class="form-control" id="password" placeholder="">
                      </div>

                        <button class="btn btn-success btn-rounded navbar-btn" [routerLink]="['']">Submit</button>
                      </form>
                    <!-- <div class="col-md-9">
                      <div>
                        <input type="text" class="form-control" placeholder="Enter your E-mail address">
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="mt-3 mt-md-0">
                        <button type="button" class="btn btn-success btn-block">Subscribe</button>
                      </div>
                    </div> -->
                  </div>
                </form>
              </div>
            </div>
          </div>
          
        </div>
      </div>
      <!-- end row -->
    </div>
    <!-- end container -->
  </section>