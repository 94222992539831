import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from 'angularfire2/firestore';
import { Observable } from 'rxjs';


interface Product {
  id?: string;
  name: string;
  price: number;
  description: string;
}

@Injectable({
  providedIn: 'root'
})


export class ProductService {
  productCollection: AngularFirestoreCollection<Product>;
  product: Observable<Product[]>;
  productDoc: AngularFirestoreDocument<Product>;

  constructor(public afs: AngularFirestore) {
    this.productCollection = this.afs.collection('services');
    this.product = this.productCollection.valueChanges();
  }

  getProducts() {
    return this.product;
  }


  addProduct(item: Product) {
    return this.afs.collection('services').doc(item.id).set({
      id: item.id,
      name: item.name,
      description: item.description,
      price: item.price * 100
    });
  }


  deleteProduct(item) {
    return this.afs.collection('services').doc(item).delete();
  }

  updateProduct(item: Product) {
    console.log(item)
    this.productDoc = this.afs.doc(`services/${item.id}`);
    this.productDoc.update(item);
  }

}
