<!--Navbar Start-->
<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark" id="navbar">
    <div class="container">
        <!-- LOGO -->
        <a class="logo text-uppercase" href="/">
            <img src="assets/images/logo-dark.png" alt="" class="logo-dark" height="20" />
        </a>

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
            aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
            <i class="mdi mdi-menu"></i>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse" style="justify-content: end">
            <button class="btn btn-success btn-rounded navbar-btn" [routerLink]="['']">Home</button>
        </div>
    </div>
</nav>



<section id="home"
    style="background: url(assets/images/bg-2.png) center center; height: 25vh; justify-content: center; align-items: center; text-align: center; padding: 100px 0 100px 0">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8">

                <div class="hero-wrapper text-center mb-4">
                    <p class="font-16 text-uppercase">Pacific Partners</p>
                    <h1 class="hero-title mb-4">
                        <span class="text-success">Consultation Form</span>
                    </h1>

                    <p>Help us get a better idea of the services your interested in!</p>
                </div>


                <div class="text-center mt-4">
                    <div class="subscribe">
                        <form>
                            <div class="row">
                                <form class="registration-form">
                                    <div style="padding: 40px 0 40px 0px">


                                        <div class="form-group mb-4">
                                            <label class="font-15">First Name</label>
                                            <input type="text" class="form-control" id="name" placeholder="">
                                        </div>
                                        <div class="form-group mb-4">
                                            <label class="font-15">Last Name</label>
                                            <input type="text" class="form-control" id="name" placeholder="">
                                        </div>
                                        <div class="form-group mb-4">
                                            <label class="font-15">Email</label>
                                            <input type="email" class="form-control" id="email" placeholder="">
                                        </div>
                                        <div class="form-group mb-3">
                                            <label class="font-15">Phone Number</label>
                                            <input type="password" class="form-control" id="password" placeholder="">
                                        </div>


                                    </div>




                                    <label for="newsletter">Please select the services you're interested in.</label>
                                    <div *ngFor="let item of productsList;  let i=index">


                                        <div class="form-check text-left mb-4">
                                            <input class="form-check-input" type="checkbox" value=""
                                                id="flexCheckDefault">
                                            <label class="form-check-label" for="flexCheckDefault">
                                                {{item.name}}
                                            </label>
                                        </div>




                                    </div>






                                    <div style="padding: 40px 0 40px 0px">
                                        <button class="btn btn-success btn-rounded navbar-btn"
                                            [routerLink]="['']">Submit</button>
                                    </div>


                                </form>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>



<!-- <div id="section_1">
    <nz-steps [nzCurrent]="current">
        <nz-step nzTitle="Finished"></nz-step>
        <nz-step nzTitle="In Progress"></nz-step>
        <nz-step nzTitle="Waiting"></nz-step>
      </nz-steps>
  
      <div class="steps-content">


        <ng-container *ngIf="index === 0">
            GET STARTED
        </ng-container>

        <ng-container *ngIf="index === 1">
            <div class="form-group mb-4">
                <label class="font-15">Your Name</label>
                <input type="text" class="form-control" id="name" placeholder="">
              </div>
              
              <div class="form-group mb-4">
                <label class="font-15">Business Name</label>
                <input type="text" class="form-control" id="name" placeholder="">
              </div>

              <div class="form-group mb-4">
                <label class="font-15">Business Industry</label>
                <input type="text" class="form-control" id="name" placeholder="">
              </div>

              <div class="form-group mb-4">
                <label class="font-15">Your Email</label>
                <input type="email" class="form-control" id="email" placeholder="">
              </div>
              <div class="form-group mb-3">
                <label class="font-15">Phone Number</label>
                <input type="phone" class="form-control" id="password" placeholder="">
              </div>
        </ng-container>

        <ng-container *ngIf="index === 2">
            <div style="display: flex; justify-content: center; align-items: center">
                <nz-card>
                  <nz-table #productsListTable [nzData]="displayData" [nzShowPagination]="false">
                      <thead>
                          <tr>
                              <th *ngFor="let column of orderColumn" [nzSortFn]="column.compare">{{ column.title }}</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr *ngFor="let item of productsListTable.data;  let i=index">
                              <td>#{{item.id}}</td>
                              <td>
                                  <div class="d-flex align-items-center">
                                      <nz-avatar nzShape="square" [nzSize]="60" nzIcon="picture" [nzSrc]="item.avatar"></nz-avatar>
                                      <span class="m-l-10">{{item.name}}</span>
                                  </div>
                              </td>
                              <td>{{item.category}}</td>
                              <td>${{item.price/100 | number:'3.2-5'}}</td>
              
                              <td class="text-md-right">
                                  <button nz-button nzType="default" nzShape="circle" nz-tooltip nzTooltipTitle="Add">
                                      <i nz-icon nzType="plus" theme="outline"></i>
                                  </button>
                              </td>
                          </tr>
                      </tbody>
                  </nz-table>
              </nz-card>
              </div>
              
              <div style="display: grid; justify-content: center;">
                <button class="btn btn-success btn-rounded navbar-btn" [routerLink]="['/checkout']">Checkout</button>
              </div>
        </ng-container>


      </div>


      
      <div class="steps-action">
        <button nz-button nzType="default" (click)="pre()" *ngIf="current > 0">
          <span>Previous</span>
        </button>
        <button nz-button nzType="default" (click)="next()" *ngIf="current < 2">
          <span>Next</span>
        </button>
        <button nz-button nzType="primary" (click)="done()" *ngIf="current === 2">
          <span>Done</span>
        </button>
      </div>
</div> -->