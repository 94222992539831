
<!--Navbar Start-->
<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark"
  id="navbar">
  <div class="container">
    <!-- LOGO -->
    <a class="logo text-uppercase" href="/">
      <img src="assets/images/logo-dark.png" alt="" class="logo-dark" height="20" />
    </a>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
      aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
      <i class="mdi mdi-menu"></i>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse" style="justify-content: end">
      <button class="btn btn-success btn-rounded navbar-btn" [routerLink]="['']">Home</button>
    </div>
  </div>
</nav>



<section id="home" style="background: url(assets/images/bg-2.png) center center; height: 25vh; justify-content: center; align-items: center; text-align: center; padding: 100px 0 100px 0">
  <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">

          <div class="hero-wrapper text-center mb-4">
            <p class="font-16 text-uppercase">Pacific Partners</p>
            <h1 class="hero-title mb-4">
              Contact
              <span class="text-success">Form</span>
            </h1>

            <p>Yay! New Friends!</p>

            <div class="text-center mt-4">
              <div class="subscribe">
                <form>
                  <div class="row">
                    <form class="registration-form">
                        <div class="form-group mb-4">
                          <label class="font-15">Your Name</label>
                          <input type="text" class="form-control" id="name" placeholder="">
                        </div>
                        <div class="form-group mb-4">
                          <label class="font-15">Your Email</label>
                          <input type="email" class="form-control" id="email" placeholder="">
                        </div>
                        <div class="form-group mb-3">
                          <label class="font-15">Password</label>
                          <input type="password" class="form-control" id="password" placeholder="">
                        </div>
                        <button class="btn btn-success btn-rounded navbar-btn" [routerLink]="['']">Submit</button>
                      </form>
                    <!-- <div class="col-md-9">
                      <div>
                        <input type="text" class="form-control" placeholder="Enter your E-mail address">
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="mt-3 mt-md-0">
                        <button type="button" class="btn btn-success btn-block">Subscribe</button>
                      </div>
                    </div> -->
                  </div>
                </form>
              </div>
            </div>
          </div>
          
        </div>
      </div>
      <!-- end row -->
    </div>
    <!-- end container -->
  </section>
  <!-- Hero section End -->
