import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';



interface DataItem {
  id: number;
  name: string;
  category: string;
  price: number;
  quantity: number;
  description: string;
}


@Component({
  selector: 'app-consultation-form',
  templateUrl: './consultation-form.component.html',
  styleUrls: ['./consultation-form.component.css', '../home.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class ConsultationFormComponent implements OnInit {

  validateForm: FormGroup;

  constructor(
    private fb: FormBuilder
  ) {
  }


  ngOnInit(): void {
    this.validateForm = this.fb.group({
      note: [null, [Validators.required]],
      gender: [null, [Validators.required]]
    });
  }

  submitForm() {
    console.log('submitted form')
  }

  productsList = [
    {
      name: 'Website - Package',
      category: 'Website, Landing Page, Payment Processing',
      avatar: 'assets/images/others/thumb-9.jpg',
      price: 100000,
      checked: false,
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
    },
    {
      name: 'Logo Design',
      category: 'Marketing',
      avatar: 'assets/images/others/thumb-9.jpg',
      price: 16000,
      checked: false,
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
    },
    {
      name: 'Brand Strategy',
      category: 'Marketing',
      avatar: 'assets/images/others/thumb-9.jpg',
      price: 25000,
      checked: false,
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
    },
    {
      name: 'Branding Stationary',
      category: 'Marketing',
      avatar: 'assets/images/others/thumb-9.jpg',
      price: 10000,
      checked: false,
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
    },
    {
      name: 'Email Marketing',
      category: 'Marketing',
      avatar: 'assets/images/others/thumb-9.jpg',
      price: 0,
      checked: false,
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
    },
    {
      name: 'Google Analytics',
      category: 'Marketing',
      avatar: 'assets/images/others/thumb-9.jpg',
      price: 25000,
      checked: false,
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
    },
    {
      name: 'Search Engine Optimization (SEO)',
      category: 'Marketing',
      avatar: 'assets/images/others/thumb-9.jpg',
      price: 25000,
      checked: false,
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
    },
    {
      name: 'Social Media Management',
      category: 'Social Media',
      avatar: 'assets/images/others/thumb-9.jpg',
      price: 100000,
      checked: false,
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
    },
    {
      name: 'Social Media Ad Management',
      category: 'Social Media',
      avatar: 'assets/images/others/thumb-9.jpg',
      price: 0,
      checked: false,
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
    },
    {
      name: 'Social Media Content Creation',
      category: 'Social Media',
      avatar: 'assets/images/others/thumb-9.jpg',
      price: 100000,
      checked: false,
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
    },
    {
      name: 'Social Media Growth',
      category: 'Social Media',
      avatar: 'assets/images/others/thumb-9.jpg',
      price: 100000,
      checked: false,
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
    }
  ]



}
