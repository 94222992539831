import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-marketing',
    templateUrl: './marketing.component.html',
    styleUrls: ['./marketing.component.css', '../home.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class MarketingComponent implements OnInit {

    pricingData = [
        {
            title: 'Economy',
            price: 19,
            bandwidth: '1GB',
            onlinespace: '50MB',
            support: 'No'
        },
        {
            title: 'Premium',
            price: 29,
            bandwidth: '2GB',
            onlinespace: '1GB',
            support: 'No'
        },
        {
            title: 'Developer',
            price: 39,
            bandwidth: '3GB',
            onlinespace: '2GB',
            support: 'Yes'
        },
    ];

    seoData = [
        {
            title: 'Express',
            price: 19,
            bandwidth: '1GB',
            onlinespace: '50MB',
            support: 'No'
        },
        {
            title: 'Basic',
            price: 29,
            bandwidth: '2GB',
            onlinespace: '1GB',
            support: 'No'
        },
        {
            title: 'Premium',
            price: 39,
            bandwidth: '3GB',
            onlinespace: '2GB',
            support: 'Yes'
        },
    ];

    constructor() { }

    ngOnInit(): void {
    }


    windowScroll() {
        const navbar = document.getElementById('navbar');
        if (document.body.scrollTop >= 50 || document.documentElement.scrollTop > 50) {
            navbar.classList.add('nav-sticky');
        } else {
            navbar.classList.remove('nav-sticky');
        }
    }

    /**
   * Toggle navbar
   */
    toggleMenu() {
        document.getElementById('navbarCollapse').classList.toggle('show');
    }

}
