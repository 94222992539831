import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-ecommerce',
    templateUrl: './ecommerce.component.html',
    styleUrls: ['./ecommerce.component.css', '../home.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class EcommerceComponent implements OnInit {


    ngOnInit(): void {
    }

    constructor() { }


    pricingData = [
        {
            title: 'Express',
            price: 75000,
            bandwidth: '2 hr',
            onlinespace: '0',
            support: 'No'
        },
        {
            title: 'Basic',
            price: 125000,
            bandwidth: '5 hr',
            onlinespace: '1GB',
            support: 'No'
        },
        {
            title: 'Premium',
            price: 200000,
            bandwidth: '10 hr',
            onlinespace: '2GB',
            support: 'Yes'
        },
    ];
    /**
 * Window scroll method
 */
    windowScroll() {
        const navbar = document.getElementById('navbar');
        if (document.body.scrollTop >= 50 || document.documentElement.scrollTop > 50) {
            navbar.classList.add('nav-sticky');
        } else {
            navbar.classList.remove('nav-sticky');
        }
    }


    /**
     * Toggle navbar
     */
    toggleMenu() {
        document.getElementById('navbarCollapse').classList.toggle('show');
    }






}
